<template>
  <b-field
    class="search-field"
    :class="{'is-expanded': expanded}">
    <b-input
      v-model="searchTerm"
      type="search"
      icon="search"
      :placeholder="placeholder"
      :expanded="expanded"
      @input="debounceSearch"/>
  </b-field>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  name: 'search-field',
  props: {
    isSearching: {
      type: Boolean,
      default: false
    },
    delay: {
      type: Number,
      default: 800
    },
    placeholder: {
      type: String,
      default: null
    },
    expanded: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchTerm: ''
    }
  },
  computed: {
    searchDebouncer() {
      return debounce(this.search, this.delay)
    }
  },
  methods: {
    debounceSearch() {
      if (!this.searchTerm) {
        return this.$emit('search', this.searchTerm)
      }

      this.$emit('update:isSearching', true)
      this.searchDebouncer.cancel()
      this.searchDebouncer()
    },
    search() {
      this.$emit('update:isSearching', false)
      this.$emit('search', this.searchTerm)
    }
  }
}
</script>

<style lang="scss" scoped>
.search-field {
  &:not(.is-expanded) {
    display: inline-block;
  }

  &::v-deep {
    .input,
    .icon {
      @extend %body;
    }
  }
}
</style>
